import { registerApplication, start, addErrorHandler,getAppStatus,LOAD_ERROR } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
  
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();



start();

addErrorHandler(err => {
  debugger;
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
      System.delete(System.resolve(err.appOrParcelName));
  }
  setTimeout(()=>{
    let el = document.body;
  el.innerHTML = "<h1>Unable to load the app</h1>" + "<h4>" + err + "</h4>";
  document.body.appendChild(el);
  },1000);
  
})
